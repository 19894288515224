<template>
<loader v-bind="{ loading }">
  <columns>
    <column>
      <columns>
        <column>
          <div class="box is-fullheight is-round p-5">
            <icon icon="helmet-safety" class="fa-2x has-text-grey"/>
            <p>
              # of Work Orders In Progress
            </p>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-3">{{ overview.in_progress_work_orders | abbreviateCount }}</p>
            </div>
          </div>
        </column>
        <column>
          <div class="box is-fullheight is-round p-5">
            <icon icon="helmet-safety" class="fa-2x has-text-grey"/>
            <p>
              Total # of Work Orders
            </p>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-3">{{ overview.total_work_orders | abbreviateCount }}</p>
              <difference-tag 
                :last="overview.work_orders_last_month" 
                :current="overview.total_work_orders"
              />
            </div>
          </div>
        </column>
        <column>
          <div class="box is-fullheight is-round p-5">
            <icon icon="barcode" class="fa-2x has-text-grey"/>
            <p>
              Total # of Checklists Performed
            </p>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-3">{{ overview.total_checklists | abbreviateCount }}</p>
              <difference-tag 
                :last="overview.passed_checklists_last_month + overview.failed_checklists_last_month" 
                :current="overview.failed_checklists_this_month + overview.passed_checklists_last_month"
              />
            </div>
          </div>
        </column>
        <column>
          <div class="box is-fullheight is-round p-5">
            <icon icon="bolt" class="fa-2x has-text-grey"/>
            <p>
              Checklist Failure Rate
            </p>
            <div class="is-flex is-align-items-center is-justify-content-space-between">
              <p class="is-size-3">{{ checklistFailureRate.toFixed(2) + '%' }}</p>
              <difference-tag 
                :last="overview.failed_checklists_last_month"
                :current="overview.failed_checklists_this_month" />
            </div>
          </div>
        </column>
      </columns>

      <h4 class="title is-4">Recent Checklist Activity</h4>

       <div class="index-rows">
          <div v-for="checklist in overview.recent_checklists" :key="checklist.id" class="box is-marginless">
            <columns>
              <column>
                <router-link class="has-text-weight-bold is-block" :to="{
                name: 'checklist-manager',
                params: {
                  checklist: checklist.uuid
                }}">
                    {{ checklist.checklist.name }}
                </router-link>
                <small class="has-text-grey">
                  Active {{ checklist.updated_at | asCalendar }}. Performed by {{ checklist.user.full_name }} on <router-link :to="{
                    name: 'work-order-manager',
                    params: {
                      workOrder: checklist.work_order.uuid
                    }
                  }">Work Order #{{ checklist.work_order.number }}</router-link>
                </small>
              </column>
              <column class="is-2 is-flex is-align-items-center is-justify-content-end">
                <inspectable-name
                  with-icon
                  :type="checklist.inspectable_type"
                  :inspectable="checklist.inspectable"
                />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <checklist-images-count 
                  :answers="checklist.answers"
                />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <circular-progress-bar
                  tooltip="Current progress for this checklist"
                  :total="checklist.answers.length"
                  :total-complete="checklist.answers.filter(answer => answer.complete).length"
                  backgroundColor="#212127"
                />
              </column>
              <column class="is-1 is-flex is-align-items-center is-justify-content-end">
                <outcome-tag 
                  :status="checklist.status"
                />
              </column>
            </columns>
          </div>
        </div>
    </column>
    <column class="is-3"></column>
  </columns>
</loader>  
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  
  data: () => ({
    loading: true,
  }),

  async beforeCreate() {
    await this.$store.dispatch('team/loadOverview', this.$route.params.team)
    this.loading = false
  },

  computed: {
    ...mapGetters('team', [
      'overview'
    ]),
    checklistFailureRate() {
      if (this.overview.total_checklists === 0) return 0
      return (this.overview.failed_checklists / this.overview.total_checklists) * 100
    },
    checklistFailureRateLastMonth() {
      if (this.overview.failed_checklists_last_month === 0) return 0
      return (this.overview.failed_checklists / this.overview.failed_checklists_last_month) * 100
    }
  }

}
</script>